.copyright {
    background-color: #001124;
    color: #ededed;
    font-size: 12px;
    text-align: center;
    height: 40px;
    display: flex;
    bottom: 0px;
    /* right: 88px; */
    width: 100%;
    position: fixed;
    justify-content: center;
    align-items: center;
}