/* LeftMenu.css */

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.left-sidebar {
  max-height: 100%;
  /* height: 100%; */
  background: linear-gradient(0deg, rgba(7, 169, 122, 1) 0%, rgba(5, 84, 141, 1) 56%);  width: 256px;
  color: #ecf0f1;
  overflow-x: hidden;
  position: fixed;
  top: 90px;
  left: 40px;
  bottom: 0;
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;

  h2 {
    color: #fff;
    text-align: left;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;
    flex-direction: column;

    .dropdown {
      position: relative;
      align-items: center;

      .dropdown-btn {
        cursor: pointer;
        font-size: 1.2rem;
        padding: 5px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
      }

      .expand-btn,
      .collapse-btn {
        cursor: pointer;
        font-size: 1.2rem;
        padding: 5px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        color: #ecf0f1;
        fill: white;

        /* &:hover {
          color: red;
        } */
      }

      .expand-btn svg,
      .collapse-btn svg {
        margin-right: 5px;
      }

      .dropdown-content {
        position: relative;
        top: 100%;
        
        /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
        z-index: 1;

        li {
          cursor: pointer;
          font-size: 1.2rem;
          margin-top: 15px;
          padding: 5px;
          transition: all 0.3s;
          margin-left: 50px;

          &:hover {
            background-color: rgb(43, 100, 140);
            border-radius: 9px;
          }
        }
      }

      .icons {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    li {
      position: relative;
      cursor: pointer;
      font-size: 1.2rem;
      margin-top: 15px;
      padding: 5px;
      transition: all 0.3s;
      border-radius: 5px;

      /* &:hover{
        background-color:;
      } */
    }
  }
}

.content {
  flex: 1;
  padding: 20px 20px 45px;
  margin-left: 340px;
  margin-right: 100px;
  transition: margin-left 0.5s;
  margin-top: 80px;
  margin-bottom: 0;
}

